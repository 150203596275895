<template>
  <footer class="footer">
    <div class="footer-container">
      <div class="footer-list">
        <div class="footer-logo">
          <div class="footer-container-img">
            <div @click="changeHeader" class="icon-form"></div>
            <img :src="require('../assets/images/betshawty-logo.png')" alt="footer-logo">
          </div>
          <div class="box-dropdown">
                <div class="lag-container">
                  <div class="container-lag">
                        <div class="container-langs" v-if="this.$i18n.locale =='en'">
                          <div class="lang" @click="changeLanguage('en')"> English <img src="../assets/images/engl.png" alt="english"></div>
                          <div class="lang" @click="changeLanguage('es')"> Español <img src="../assets/images/spanish.png" alt="spanish"></div>
                          <!-- <div class="lang" @click="changeLanguage('pt')"> Portugués <img src="../assets/images/port.png" alt="portugés"></div> -->
                        </div>
                        <div class="container-langs" v-if="this.$i18n.locale =='es'">
                          <div class="lang" @click="changeLanguage('es')"> Español <img src="../assets/images/spanish.png" alt="spanish"></div>
                          <div class="lang" @click="changeLanguage('en')"> English <img src="../assets/images/engl.png" alt="english"></div>
                          <!-- <div class="lang" @click="changeLanguage('pt')"> Portugués <img src="../assets/images/port.png" alt="portugés"></div> -->
                        </div>
                        <!-- <div class="container-langs" v-if="this.$i18n.locale =='pt'">
                          <div class="lang" @click="changeLanguage('pt')"> Portugués <img src="../assets/images/port.png" alt="portugés"></div>
                          <div class="lang" @click="changeLanguage('es')"> Español <img src="../assets/images/spanish.png" alt="spanish"></div>
                          <div class="lang" @click="changeLanguage('en')"> English <img src="../assets/images/engl.png" alt="english"></div>
                        </div> -->
                        
                        <svg class="flecha" xmlns="http://www.w3.org/2000/svg" width="7" height="14" viewBox="0 0 10 16" fill="#ff369b">
                        <path d="M1 1L8 8L1 15" stroke="#ff369b" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                        <!-- Resto del contenido de tu componente -->
                  </div>
                </div>
            </div>

        </div>
        <ul>
          <li v-for="(slide, index)  in routesColumn1" :key="index">
            <a :href="slide.route">{{ slide.name }}</a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>


<script>
/*   import AgentButton from "@/components/agent-button.vue"
  import MobileMenu from '@/components/mobile-menu.vue' */
import { useI18n } from 'vue-i18n';
import { reactive, watch } from 'vue';
import {computed} from 'vue'
// import { toRef } from 'vue';

export default {
  name: "FooterComponent",
  components: {
    /*       AgentButton,
          MobileMenu */
  },
  watch: {
      $route(newVar){
      let pathName = computed(() =>newVar);
      this.detectLang(pathName.value.name);
    }
  },
  props:{
        loginForm: {type:Boolean,defaul:true}
    },
  methods: {
 
    openModal() {
        this.$refs.modal.openModal();
      },
      changeLanguage(locale) {
        if(this.$i18n.locale != locale){
          this.$i18n.locale = locale;
          const t = this.$t;
          const currentRoute = this.$router.currentRoute.value;

          if (currentRoute.path !== '/' && currentRoute.path !== '/apk') {
            const translatedPath = `/${t(`routes.${currentRoute.name}`)}`;
            this.$router.push(translatedPath);
          }
        }
        
      },
      getTranslatedRoutes(t) {
        
          const routes = this.$router.options.routes.map((route) => ({
            ...route,
            path: route.path !== '/' ? `/${t(`routes.${route.name}`)}` : '/',
          }));

          return routes;
        },
        detectLang(pathName){
          if(pathName.includes("langes")){
            this.$i18n.locale = "es";
            this.changeLanguage("es")
          }else if(pathName.includes("langen")){
            this.$i18n.locale = "en";
            this.changeLanguage("en")
          }else if(pathName.includes("langpt")){
            this.$i18n.locale = "pt";
            this.changeLanguage("pt")
          }else{
            console.log("default")
          }
      }
  },
  setup(props, { emit }) {

    const { t, locale } = useI18n();

    const changeHeader = () => {
      emit('changeHeader');
    }
    const routesColumn1 = reactive([
      {
        name: t("footerColum1.0.name"),
        route: t("footerColum1.0.route")
      },
      {
        name: t("footerColum1.1.name"),
        route: t("footerColum1.1.route")
      }
    ])

    // Observar los cambios de idioma y actualiza los slides
    const updateSlides = () => {
      routesColumn1.forEach((opcion, index) => {
        opcion.name = t(`footerColum1.${index}.name`);
        opcion.route = t(`footerColum1.${index}.route`);
      });
    };

    // Escuchar los cambios de idioma y actualiza los slides
    watch(locale, () => {
      updateSlides();
    });
    return {
      routesColumn1,
      changeHeader
    }

  },
};
</script>
